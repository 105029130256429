import React from "react";
import Frame from "../../assests/Frame 8.png";
import "./Homepage.css";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <section className="w-full">
      <div>
        <h3 className="font-bold text-2xl">About us</h3>
      </div>

      <div className="flex gap-[4rem] about">
        <div className="lg:w-[50vw] image-div mt-2 ">
          {/* <h1 className="font-bold text-4xl">Unik Photography</h1> */}
          <img
            src={Frame}
            alt="image"
            className="w-[600px] h-[200px] object-cover mt-2"
          />
        </div>

        <div className="lg:w-[50vw] flex flex-col  items-center justify-center">
          <p className="">
            Our services encompass bornbaby, kids, wedding, pre-wedding,
            portraits, cinematography, maternity, indoor, outdoor, and film
            production. We specialize in creating cherished memories through
            expert photography and videography across diverse genres, including
            weddings, preweddings, cinematic storytelling, and film production.
            From capturing the innocence of newborns to immortalizing the joyous
            moments of weddings, our skilled team is dedicated to providing
            high-quality and memorable visual content. Unik Photography is not
            just a service; it's an experience that transforms your special
            moments into timeless treasures.
          </p>

          <div className="mt-[1rem]">
            <button className="bg-white text-black py-[1rem] px-[2rem] rounded-full">
              <Link to="/service">
                <h4>Learn More</h4>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
