import React from 'react';
import './Nav.css';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {Link, NavLink} from 'react-router-dom';
const Nav = () => {
 
    const[bgrOpened, setBgrOpened] = useState(false);
    
    const[close,setClose] = useState(false)

     const getBarStyles = (bgrOpened) => {
      if(document.documentElement.clientWidth <= 440){
        return{
            display: !bgrOpened && "none"
        }
      }
     }

const clickHandler = () =>{
 
  setClose(true);
}
  return (

      <nav className="text-white bg-[#0F0F0F22] backdrop-blur-[15px] sticky px-[16px] sm:px-[100px] md:px-[40px] lg:px-[120px] w-full z-20 top-0 start-0">
       <div className="flex flex-wrap items-center justify-between w-full p-4">
          <div className='flex items-center gap-5'>
          <div>
          <a href="/" className="flex items-around space-x-3 rtl:space-x-reverse">
            <h4 className="self-center  font-bold whitespace-nowrap text-white">UNIK PHOTOGRAPHY</h4>
          </a>
          </div>
         
          <div className="nav-item items-center justify-between hidden w-full md:flex md:w-auto md:ml-14 "id="navbar-sticky">
            <ul className="text-md flex flex-col p-4 md:p-0 mt-4 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0">
              <li>
                <NavLink to="/" className="link-uline block py-2 px-3 text-gray-500 md:p-0" aria-current="page">Home</NavLink>
              </li>
              <li>
                <NavLink to="/service" className="link-uline block py-2 px-3 text-gray-500 md:p-0">Services</NavLink>
              </li>
              <li>
                <NavLink to="/portfolio" className="link-uline block py-2 px-3 text-gray-500 md:p-0 ">Portfolio</NavLink>
              </li>
              <li>
                <NavLink to="/preset" className="link-uline block py-2 px-3 text-gray-500 md:p-0 ">Presets</NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="link-uline block py-2 px-3 text-gray-500 md:p-0 ">Contact</NavLink>
              </li>
            </ul>
          </div>
          </div>

          <div className="social-icon flex flex-row items-center justify-evenly space-x-3">
            <div>
              <a href='https://www.facebook.com/people/Unik/100068988004276/?mibextid=ZbWKwL' target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-white fill-current hover:text-[#1877F2] transition-all ease-in-out"
                fill="currentColor"
                viewBox="0 0 24 24"
                >
                <path
                  d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
              </a>
            </div>

            <div>
              <a href='https://www.instagram.com/unik_photography_beed/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-white fill-current hover:text-[#cd486b] transition-all ease-in-out"
                fill="currentColor"
                viewBox="0 0 24 24">
                <path
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
              </a>
            </div>

            <div>
              <a href='https://www.youtube.com/@theunikphotography812' target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-white fill-current hover:text-[#CD201F] transition-all ease-in-out"
                fill="currentColor"
                viewBox="0 0 24 24">
                <path
                  d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
              </svg>
              </a>
            </div> 
          </div>

          <div className=" hmbg flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
             <button onClick={()=>{setBgrOpened((prev)=>!prev) ;setClose(false) }}  data-collapse-toggle="navbar-sticky" type="button" className=" ham inline-flex  items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                  </svg>
            </button>
          </div> 
       </div >

          <div className='sm:hidden absolute right-0 bg-[#0F0F0F22] backdrop-blur-[15px] flex justify-end' style={{display: close ? "none":" "}}>
              <OutsideClickHandler onOutsideClick={() => setBgrOpened(false)}>
                  <div className= " " style={getBarStyles(bgrOpened)}>
                    <ul className="text-gray-500 dark:text-gray-400 font-medium px-8">
                              <li className="my-3">
                                  <Link to="/" onClick={clickHandler} className="hover:text-white hover:underline">Home</Link>
                              </li>
                              <li className="mb-3">
                                  <Link to="/service" onClick={clickHandler} className="hover:text-white hover:underline">Service</Link>
                              </li>
                              <li className="mb-3">
                                  <Link to="/portfolio"onClick={clickHandler} className="hover:text-white hover:underline">Portfolio</Link>
                              </li>
                              <li className="mb-3">
                                  <Link to="/preset" onClick={clickHandler} className="hover:text-white hover:underline">Preset</Link>
                              </li>
                              <li className="mb-3">
                                  <Link to="/contact"onClick={clickHandler}  className="hover:text-white hover:underline">Contact us</Link>
                              </li>
                      </ul>
                  </div>
               </OutsideClickHandler>
          </div>
      </nav>
  )
}
export default Nav;