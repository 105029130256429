import { createContext, useState,useEffect } from "react";


const UnikContext = createContext({});

export const UnikProvider = ({ children }) => {

    const [ presets,setPresets] = useState([]);
   
    return (
        <UnikContext.Provider value={{ presets,setPresets}}>
            {children}
        </UnikContext.Provider>
    )
}

export default UnikContext;