import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import useUnikContext from '../../hooks/useUnikContext';

const PresetCard = ({title,image1,image2,xmpFile}) =>{
  
  const downloadXmpFile = async (file) => {
    console.log(file)
    try {
      const response = await fetch(file,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
      console.log(response)
      const xmpData = await response.text();

      const blob = new Blob([xmpData], { type: 'application/octet-stream' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'yourPresetFile.xmp';
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading XMP file:', error);
    }
  };

  return(

  // const { imageFileUrl1, imageFileUrl2 } = defaultValue[0];
  <div  className='space-y-5 bg-[#233942] p-5 backdrop-blur-[10px] h-[400px] w-[400px]'>
    <h4>{title || <Skeleton/>}</h4>
    <div className='w-full h-3/4 flex gap-2'>
      <div className='bg-white w-full relative'>
      <span className='absolute top-2 right-2 text-white bg-black px-3 py-1 bg-opacity-40'> Before</span>
        <img src={image1 || <Skeleton/>} alt='beforeImage' className='h-full w-full object-cover' loading='lazy'/>
      </div>
      <div className='bg-white w-full relative'>
      <span className='absolute top-2 right-2 text-white bg-black px-3 py-1 bg-opacity-40'> After</span>
        {<img src={image2} alt='afterImage' className='h-full w-full object-cover' loading='lazy'/>|| <Skeleton/>}
        
      </div>
    </div>
    <div className='w-full flex justify-end'>
      <button className='border-white border-[1px] px-5 py-2' onClick={()=>downloadXmpFile(xmpFile)}>Download Preset</button>
    </div>
  </div>
)}

const Preset = () => {

  const {presets,setPresets} = useUnikContext();
  console.log(presets)
 

  // const [presets,setPresets] = useState([]);

  useEffect(()=>{

    const getPresets = async () =>{
            
      try{
          const response = await fetch (`https://api.unikphotography.com/api/preset`,{
          })
          if(response.ok){
              const result =await response.json();
              console.log(result)
              setPresets(result.data);
          }else{
              console.log("error in fetching presets")
          }
      }catch(e){
          console.log("there was an error in getPresets:", e.message)
      }
  }
  getPresets()
  },[])

  const backgroundImageUrl = 'https://images.wallpaperscraft.com/image/single/photographer_camera_dark_133781_3840x2400.jpg';

  const containerStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center', 
    width: '100%',
    height: '100%',
  };
  return (
    <div style={containerStyle} className='relative min-h-[100vh] sm:h-[100vh] w-full text-white py-10 pb-20'>
      <div className='w-full h-full absolute top-0 left-0 bg-[#141F24] bg-opacity-40 backdrop-blur-[7px]'></div>
      <div className='relative z-10 px-[16px] space-y-10 md:px-[42px]'> 
          <h2 className='text-center '>Presets</h2>
        <div className='md:px-[20px] flex flex-wrap gap-10'>
        
        {
          presets && presets.map((preset)=>{
            return(
            <PresetCard
            key={preset.presetId}
            title={preset.name}
            image1={preset.imageFileUrl1}
            image2={preset.imageFileUrl2}
            xmpFile ={preset.xmpFileUrl}
            />
          )})
        }
       
      

        </div>

      </div>

    </div>
  )
}

export default React.memo(Preset)
