import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";


const VideoRightContainer = (props) => {
    const [imgs, setImgs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [btn, setBtn] = useState(false);

    useEffect(() => {
        const size = 20;
        const getPortfolioData = async () => {
            try {
                let apiUrl = `https://api.unikphotography.com/api/portfolio?category=${props.category}&size=20&type=${props.type}`
                const res = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        // 'authorization': `Bearer ${authorization}`,
                        'Content-Type': 'application/json'
                    },

                })

                const apiData = await res.json();
                // console.log(apiData.data[0]);
                // setLoading(true);

                if (res.ok) {
                    setImgs(apiData.data[0]);
                    setBtn(true);
                    // setMoreImg(apiData.data);
                    setLoading(false);
                    console.log("success");
                }
                else {
                    console.log("Some error occurred");
                }

                // console.log(apiData.data);

            } catch (error) {
                console.log(error);
            }
        }

        getPortfolioData();
    }, []);

    function capitalizeWords(str) {
        return str.replace(/\b\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    return (
        <div className='mt-[40px]'>
            <p className='sm: hidden'>{capitalizeWords(props.category)}</p>
            <div className='lg:flex md:flex sm:block pt-[5px] geeks '>

                {
                    (btn &&
                        <video controls muted autoPlay className='top object-cover'><source src={imgs.fileUrl} /></video>)
                    ||
                    <ReactLoading type="spin" color="white"
                        height={100} width={50} />
                }

                <div className='lg: flex lg: items-center title'>
                    <h2 className='font-bold text-3xl ml-[10px]'>{capitalizeWords(props.category)} <br /> Video</h2>
                </div>

                <div className='hidden mobile-title mt-[10px]'>
                    <h2>
                        {capitalizeWords(props.category)} Video
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default VideoRightContainer
