import React from 'react'
import ServiceCard from './ServiceCard'
import ServiceCard2 from './ServiceCard2'
// import {
//     img1,
//     img2,
//     img3,
//     img4,
//     img5,
//     img6,
//     img8,
//     img9,
//     vid1
// } from './assets'

import img1 from '../../assests/services/Image1.png'
import img2 from '../../assests/services/Image2.png'
import img3 from '../../assests/services/Image3.png'
import img4 from '../../assests/services/Image4.png'
import img5 from '../../assests/services/Image5.png'
import img6 from '../../assests/services/Image6.png'
import img8 from '../../assests/services/Image8.png'
import img9 from '../../assests/services/Image9.png'
import vid1 from '../../assests/services/Video1.mp4'


const Service = () => {
    return (
        <div className='bg-[#141F24] h-full w-full text-white px-[16px] sm:px-[100px] md:px-[40px] lg:px-[120px] space-y-[100px] py-20 '>


          
            <div className='space-y-[16px] h-1/2'>
                    <h2 className=' leading-tight'>Our <br /> Services</h2>
                    <div className='space-y-[16px] md:w-1/2 text-justify'>
                        <hr className='w-[20%] lg:w-[30%]' />
                        <p>Capture the essence of life's milestones with Unik Photography. 
                        From newborns to weddings, our expert team crafts timeless visual stories, turning special moments into cherished memories. </p>
                    </div>
                </div>


                  {/* Photo Grid Starts Here */}
            <div className='service-grid-container space-y-[50px] flex flex-col items-center gap-10 md:gap-x-[50px] lg:gap-x-[90px] md:gap-y-0'>

          

                <div className='w-full space-y-[16px] lg:space-y-0 md:flex md:flex-col md:gap-5 md:justify-start md:h-[80%] lg:h-auto '>
                    <div className='w-full flex items-center gap-2 '>
                        <svg width="15px" height="15px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" class="bi bi-circle-fill">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <h3>Photography</h3>
                    </div>
                    <div className='space-y-[16px] lg:space-y-0 lg:flex lg:gap-8'>
                    <hr className='w-[20%] lg:w-[70%]' />
                    <p className='text-justify'> 
                    "Elevate your moments with our professional photography services. Specializing in portraits, events, and commercial photography,
                     we capture the essence of your story with creativity and precision. Trust us to transform your vision into timeless, unforgettable images."
                    </p>
                    </div>
                    </div>


                    <div className='md:row-span-2'>
                    <ServiceCard
                    title="Welcome to the World: Newborn Photography"
                    image = {img6}/>
                    </div>
                    <div className='md:row-span-2'>
                    <ServiceCard
                    title="Childhood Chronicles: Expressions in Every Frame"
                    image = {img4}/>
                    </div>

                    <div className=' md:row-span-3 lg:pt-[80px] xl:pt-[120px] xl:pl-[80px]'>
                    <ServiceCard2
                    title="Bump to Bliss: Maternity Moments"
                    image = {img1}/>
                    </div>

                    <div className='md:row-span-2 lg:pt-[40px] xl:pt-[70px]'>
                    <ServiceCard2
                    title="Faces of Grace: Portrait Photography Elegance"
                    image = {img5}/>
                    </div>

                    <div className='md:row-span-2 lg:pt-[40px] xl:pt-[70px]'>
                    <ServiceCard2
                    title="Cherished Vows: Wedding Photography Harmony"
                    image = {img8}/>
                    </div>
                   
                    <div className='md:row-span-2 lg:pt-[40px] xl:pt-[70px]'>
                    <ServiceCard
                    title="Before the Vows: Pre-Wedding Bliss in Frames"
                    image = {img9}/>
                    </div>

                    <div className='md:row-span-3 lg:pt-[80px] xl:pt-[120px] xl:pr-[80px]'>
                    <ServiceCard2
                    title="Cozy Moments: Indoor Photography Comfort"
                    image = {img3}/>
                    </div>
                    <div className='md:row-span-2 lg:pt-[40px] xl:pt-[70px] '>
                    <ServiceCard
                    title="Nature's Canvas: Outdoor Photography Adventures"
                    image = {img2}/>
                    </div>

                    <div className='w-full space-y-[16px] lg:space-y-0  lg:flex lg:flex-col lg:gap-5 md:row-span-2 md:pt-[190px] lg:pt-[300px]'>
                    <div className='w-full flex items-center gap-2 '>
                        <svg width="15px" height="15px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" class="bi bi-circle-fill">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <h3>Videography</h3>
                    </div>
                    <div className='space-y-[16px] lg:space-y-0 lg:flex lg:gap-8'>
                    <hr className='w-[20%] lg:w-[70%]' />
                    <p className='text-justify'>
                    "Immerse yourself in the art of storytelling through our expert videography services. 
                    From cinematic narratives to event coverage and commercial projects, we adeptly capture the heart of your story with creative flair and precision. 
                    Rely on us to turn your vision into enduring, unforgettable videos."
                    </p>
                    </div>
                    </div>

                    <div className='md:row-span-2 lg:pt-[80px] xl:pt-[110px]'>
                    <ServiceCard2
                    title="Before Forever: Pre-Wedding Love in Motion"
                    video = {vid1}/>
                    </div>

                    <div className='md:row-span-3 lg:pt-[40px] xl:pt-[70px]'>
                    <ServiceCard2
                    title="Eternal Vows: Cinematic Wedding Videography"/>
                    </div>

                    <div className='md:row-span-3 lg:pt-[80px] xl:pt-[120px]'>
                    <ServiceCard
                    title="Cinematic Odyssey: Crafting Visual Stories"/>
                    </div>

                    <div className='md:row-span-2 lg:pt-[40px] xl:pt-[70px]'>
                    <ServiceCard2
                    title="Cozy Moments: Indoor Photography Comfort"/>
                    </div>

                    <div className='md:row-span-3 lg:pt-[40px] xl:pt-[70px]'>
                    <ServiceCard
                    title="Filmography Fantasia: Bringing Stories to Life"/>
                    </div>

                    



              
                




                
            </div>
        </div>
    )
}

export default Service