import React from "react";
import { Link } from "react-router-dom/dist";

const Footer = () => {
  return (
    <div className="text-white flex justify-around w-screen ">
      <div className="flex py-5 flex-col bg-[#0F0F0F22] px-[16px] sm:px-[100px] md:px-[40px] lg:px-[120px] md:gap-8 md:gap-20 bg-#233942 bg-black text-white md:flex-row w-full md:justify-between">
        <div className="contant1 flex flex-col w-full py-2 px-2">
          <div className="logo-heading w-full h-1/4 gap-2 font-bold md:text-3xl md:mb-4">
            UNIK PHOTOGRAPHY
          </div>

          <div className="flex flex-col md:flex-row justify-between md:w-full gap-5">
            <div className="w-full flex flex-col justify-center">
              <iframe
                className="w-1/2 max-md:w-full"
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.7569873224743!2d75.73975327711372!3d18.9863368089092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc52bf24661c90d%3A0xf286ae85c1c280cd!2sThe%20Unik%20Photography!5e0!3m2!1sen!2sin!4v1705067987485!5m2!1sen!2sin"
                height="250"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>

              <div className="para-text mt-5 text-gray-400 dark:text-gray-400 hover:text-white ">
                <a
                  href="https://maps.app.goo.gl/c6N5d1joEq4PTUqK9"
                  target="_blank"
                >
                  Vishnu Suman Plaza,
                  <br />
                  Near Garge Memorial,
                  <br />
                  Canol Road,
                  <br />
                  Beed 431122
                </a>
              </div>
            </div>

            <div className="flex justify-between md:gap-[47px] md:mt-5">
              <div>
                <h2 className="mb-4 text-sm font-bold text-white uppercase ">
                  Social
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-3">
                    <a
                      href="https://www.facebook.com/people/Unik/100068988004276/?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline hover:text-white"
                    >
                      Facebook
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      href="https://www.instagram.com/unik_photography_beed/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" hover:underline hover:text-white"
                    >
                      Instagram
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      href="https://www.youtube.com/@theunikphotography812"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline hover:text-white"
                    >
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="mb-4 text-sm font-bold  uppercase text-white">
                  Pages
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-3">
                    <Link to="/" className=" hover:underline hover:text-white">
                      Home
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/service"
                      className="hover:underline hover:text-white"
                    >
                      Service
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/portfolio"
                      className="hover:underline hover:text-white"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/preset"
                      className="hover:underline hover:text-white"
                    >
                      Preset
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/contact"
                      className="hover:underline hover:text-white"
                    >
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
