import React from "react";
import Services from "./Services";
import { Link } from "react-router-dom";

const Structure = () => {
  return (
    <>
      <div className="flex-col">
        <div className="flex justify-between ">
          <div className="font-bold text-3xl">SERVICES</div>
          <button className="flex gap-2 font-semibold  cursor-pointer hover:underline items-center">
            <Link to="/service" aria-current="page" className="flex gap-2">
              <h5>Explore More</h5>
              <div className="flex items-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </div>
            </Link>
          </button>
        </div>
        <div className="flex flex-shrink-0 flex-wrap gap-4 justify-center">
          <div className="w-[369px]">
            <Services
              imgdesc="1"
              heading="Born Baby"
              alternate_text="baby boy"
              details="Welcome your little one with our premier born baby services. From trendy nursery designs to personalized care, we ensure a joyous and modern start for your newborn's journey. Explore now!"
            />
          </div>
          <div className="w-[369px]">
            <Services
              imgdesc="2"
              heading="Wedding"
              alternate_text="wedding photography svg"
              details="Elevate your wedding experience with our trendsetting services. Immerse in the latest wedding trends, from exquisite venues to personalized touches, creating a celebration as unique as your love story. Explore today!"
            />
          </div>
          <div className="w-[369px]">
            <Services
              imgdesc="3"
              heading="Pre-wedding"
              alternate_text="Pre-wedding photo shoot"
              details="Discover our exquisite wedding services, tailored to make your special day unforgettable. From elegant venues to personalized decor, our dedicated team ensures a seamless and magical celebration of love and commitment."
            />
          </div>
          <div className="w-[369px]">
            <Services
              imgdesc="4"
              heading="Portraits"
              alternate_text="camera portraits"
              details="Transform moments into timeless memories with our portrait services. Our skilled photographers capture the essence of your personality, creating stunning, high-quality portraits that reflect your unique style and story."
            />
          </div>
          <div className="w-[369px]">
            <Services
              imgdesc="5"
              heading="Cinematography"
              alternate_text="cinematography video camera"
              details="Transform moments into timeless memories with our portrait services. Our skilled photographers capture the essence of your personality, creating stunning, high-quality portraits that reflect your unique style and story."
            />
          </div>
          <div className="w-[369px]">
            <Services
              imgdesc="6"
              heading="Maternity"
              alternate_text="mother with a baby maternity"
              details="Embrace the beauty of your journey with our maternity photography services. Expertly capturing the glow of motherhood, we create timeless images that celebrate the anticipation and love during this special chapter."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Structure;
