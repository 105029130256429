import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import ReactLoading from "react-loading";


const ImageContainerRight = (props) => {

    const [imgs, setImgs] = useState([]);
    const [moreImg, setMoreImg] = useState([]);
    const [display, setDisplay] = useState('');
    const [loading, setLoading] = useState(true);
    const [close, setClose] = useState("block");
    const [btn, setBtn] = useState(false);
    const [mainImg, setMainImg] = useState("");

    useEffect(() => {
        const size = 20;
        const getPortfolioData = async () => {
            try {

                let apiUrl = `https://api.unikphotography.com/api/portfolio?category=${props.category}&size=20&type=${props.type}`
                const res = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        // 'authorization': `Bearer ${authorization}`,
                        'Content-Type': 'application/json'
                    },

                })

                const apiData = await res.json();
                console.log(apiData);
                // console.log(apiData);
                // setLoading(true);

                if (res.ok) {
                    setImgs(apiData.data[0]);
                    setMoreImg(apiData.data);
                    setLoading(false);
                    console.log("success");
                }
                else {
                    console.log("Some error occurred");
                }

                // console.log(apiData.data);

            } catch (error) {
                console.log(error);
            }
        }

        getPortfolioData();
    }, []);

    // console.log(imgs);

    function capitalizeWords(str) {
        return str.replace(/\b\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    const handleClick = () => {
        // if(cat===props.category){
        setBtn(true);
        // setDisplay("none");
        setMainImg("none");
        setClose("block");
        // }
    }

    const handleClick2 = () => {
        // console.log("hi");
        setClose("none");
        setMainImg("");
    }

    return (
        <div className='mt-[20px]'>
            <p className='sm: hidden'>{capitalizeWords(props.category)}</p>
            <div className='pt-[5px] geeks '>
                <div className="pic flex mt-[2rem] pl-[5px] pr-[5px]" style={{ display: mainImg }}>

                    <div>
                        {!loading ?
                            <div className='pt-[4px] max-h-[1000px] no-repeat  overflow-hidden object-center'>
                                {/* <h3>Januray 10, 2021</h3> */}
                                <img src={imgs.fileUrl || <ReactLoading type="spin" color="white"
                                    height={100} width={50} />} className='w-full h-full object-cover' />
                                <button onClick={handleClick} className='btn'>See More</button>
                            </div>
                            :
                            <div>
                                <ReactLoading type="spin" color="white"
                                    height={100} width={50} />
                            </div>
                        }
                    </div>

                    <div className='lg: flex lg: items-center title'>
                        <h2 className='font-bold text-3xl ml-[10px]'>{capitalizeWords(props.category)} <br /> Photosession</h2>
                    </div>
                </div>

                <div className='hidden mobile-title' style={{ display: display }}>
                    <h2>
                        {capitalizeWords(props.category)} Photosession
                    </h2>
                </div>
                <div className='lg: grid lg: grid-cols-4 lg: gap-x-10 lg: gap-y-10 internal-img'>
                    {
                        btn &&
                        moreImg?.map((i) => {
                            return (
                                <div className='mt-[4px] flex justify-center sm:justify-center sm:items-center max-h-[1000px]' style={{ display: close }}>
                                    <img src={i.fileUrl} className=' object-cover ' onClick={handleClick2} />
                                </div>
                            )
                        })

                    }
                </div>
            </div>
        </div>
    )
}

export default ImageContainerRight
