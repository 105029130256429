import React from 'react'
import { Link } from 'react-router-dom'

const ServiceCard = ({title,image,video}) => {

  console.log(video)
  return (
    <div className='h-[385px] lg:h-full w-full lg:w-[90%] bg-transparent shadow-xl border-[1px] border-[#53879c5b] shadow-[#53879c2a] lg:shadow-none lg:border-0 lg:relative'>

        <div className='h-[62%] lg:h-[80%]'>
         {image ? 
         <img src={image} alt='uniq-photography' className='w-full h-full object-cover object-center'/>
        : <video className='h-full w-full object-contain object-center' loop autoPlay muted>
            <source src={video} type='video/mp4'></source>
          </video>} 
        </div>
        <div className='p-[20px] flex flex-col h-[38%] justify-between bg-[#233942] bg-opacity-95 lg:absolute lg:w-[80%] lg:h-[180px] 2xl:h-[230px] 2xl:bottom-[13%] lg:bottom-0'>
        <h3>{title}</h3>
        <Link to='/portfolio'>   <div className='flex items-center justify-between  lg:gap-5 '>
              <h4>Explore Portfolio</h4>
                <div className='border-[1px] border-white rounded-full p-2 lg:p-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 lg:w-3 lg:h-3 xl:w-6 xl:h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg>
                </div>
            </div>
            </Link>
        </div>

    </div>
  )
}

export default ServiceCard