import React from 'react';
import kids1 from '../../assets/kids/kids1.jpg';
import './Contact.css';
import { useState } from 'react';




const Contact = () => {
      const [formData, setFormData] = useState({name: '',email: '',number: '',message: ''});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);


    const apiUrl = 'https://api.unikphotography.com/api/contact-us';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error submitting form');
        }
      })
      .then(data => {
        console.log('Form submitted successfully:', data);
       
      })
      .catch(error => {
        console.error('Error:', error);
      
      });
  };

    return (
        <section className='bg-[#141F24] px-[16px] sm:px-[100px] md:px-[40px] lg:px-[120px] space-y-[100px] py-20 '>
            <div className='contact-main flex gap-[100px] justify-between'>
                <div className='max-w-[500px] max-h-[500px]'>
                    <img src={kids1} alt="" className='img object-cover' />
                </div>

                <div className='contact-right flex flex-col justify-around px-[2rem] w-full'>
                    <div className='font-bold text-3xl'>
                        <h2>Contact</h2>
                        <br/>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col gap-y-[2rem]'>
                            <div className='flex gap-x-[2rem] w-[100%]'>
                                <input type="text" placeholder='Name' id='name' name='name' className='his bg-transparent border-b-2 outline-none w-[50%] pb-2' value={formData.name} onChange={handleChange} required/>

                                <input type="tel" placeholder='Phone number' id='number' name='number' className='contact-email bg-transparent border-b-2 outline-none w-[50%] pb-2'value={formData.number} onChange={handleChange} required/>
                            </div>

                            <div>
                                <input type="email"  placeholder='Email' id='email' name='email' className='bg-transparent border-b-2 outline-none w-[100%] pb-2'value={formData.email} onChange={handleChange}/>
                            </div>
                            
                            <div >
                                <input type="text" placeholder='Message' id='message' name='message' className='w-[100%] bg-transparent border-b-2 outline-none pb-2' value={formData.message} onChange={handleChange}/>
                            </div>

                            <div className="">
                                    <input type="submit"value={isSubmitted ? 'Message Sent' : 'Send Message'}
                                    className="border-2 py-[6px] px-[12px] rounded-full cursor-pointer "
                                    style={isSubmitted ? { color: 'black', background: 'white', fontWeight:'600' } : {}}
                                    disabled={isSubmitted} />
                            </div>
                        </div>
                    </form>

                    <div className=''>
                        <h3 className='font-bold text-xl'>Reach out to us at</h3>
                        <p>nikamdattatrya913@gmail.com</p>
                        <p>+91 9850440913</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact



