import React, { useEffect, useState } from 'react';
import './Portfolio.css';

import ImageContainer from '../ImageContainer';
import ImageContainerRight from '../ImageContainerRight';
import VideoContainer from '../VideoContainer';
import VideoRightContainer from '../VideoRightContainer';
// import v2 from '../../assets/videos/v2.mp4';
// import v3 from '../../assets/videos/v3.mp4';
// import v4 from '../../assets/videos/v4.mp4';
// import v5 from '../../assets/videos/v5.mp4';
// import v6 from '../../assets/videos/v6.mp4'


var i = 0, j = 0, k = 0, l = 0;
const Portfolio = () => {

    const [imgs, setImgs] = useState([]);
    const [moreImg, setMoreImg] = useState([]);
    const [weds, setWeds] = useState([]);
    const [newBorn, setNewBorn] = useState([]);
    const [marriage, setMarriage] = useState([]);
    const [pre, setPre] = useState([]);
    const [display, setDisplay] = useState('');
    const [loading, setLoading] = useState(true);
    const [born, setBorn] = useState("");
    const [preWed, setPreWed] = useState("");
    const [close, setClose] = useState("block");
    const [btn, setBtn] = useState(false);
    const [mainImg, setMainImg] = useState("");
    const [cap, setCap] = useState('');
    const baseURL = process.env.SERVER_URL;

    return (
        // <section className=" max-w-full h-[2828px] px-[16px] py-[32px] gap-[32px]">
        <section className='bg-[#141F24] flex flex-col items-center'>
            <div className='px-[16px] py-[32px] '>
                <div className='font-bold text-4xl mr-[5px]'>
                    <h2>Portfolio</h2>
                </div>

                <div className='lg: max-w-5xl '>
                  

                    <ImageContainer category="bornbaby" type="image"/>
                    <ImageContainerRight category="kids" type="image"/>
                    <ImageContainer category="maternity" type="image"/>
                    <ImageContainerRight category="wedding" type="image"/>
                    <ImageContainer category="prewedding" type="image"/>
                    <ImageContainerRight category="indoor" type="image"/>
                    <ImageContainer category="outdoor" type="image"/>
                    <ImageContainerRight category="cinematic" type="image"/>
                    <ImageContainer category="film" type="image"/>
                    
                    <VideoContainer category="wedding" type="video"/>
                    <VideoRightContainer category="prewedding" type="video"/>
                    <VideoContainer category="cinematic" type="video"/>
                    <VideoRightContainer category="film" type="video"/>

                    {/* <ImageContainer category="film"/> */}




                </div>
            </div>
        </section>
    )
}

export default Portfolio
