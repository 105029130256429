import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

export default function App() {
  return (
    <FloatingWhatsApp
      phoneNumber="+91 9850440913"
      accountName="Unik Photography"
      chatMessage="Welcome to Unik Photography, How may I help you?"
      allowEsc
      allowClickAway
      notificationDelay="3"
      notification="true"
      notificationSound="true"
      darkMode="true"
    />
  );
}
