import React from "react";
import image1 from "../../assests/unik0102_without_name.jpg";
import image2 from "../../assests/unik0102.jpg";
import Structure from "./Structure";
import About from "./About";
const Homepage = () => {
  return (
    <div className="bg-[#141F24] h-full w-full text-white px-[16px] sm:px-[100px] md:px-[40px] lg:px-[120px] space-y-[100px] py-20 ">
      <div className="flex flex-col">
        <h2 className=" leading-tight">
          Unik <br /> Photography
        </h2>
        <div className="flex items-end mt-10">
          <picture>
            {/* Large view image */}
            <source media="(min-width: 768px)" srcSet={image1} />
            {/* Small view image */}
            <img
              className="flex  w-screen object-cover overflow-hidden"
              src={image2}
              alt="photo of holding a camera with a hand and having black background withit"
            />
          </picture>
        </div>
      </div>
      <About />
      <Structure />
    </div>
  );
};

export default Homepage;
