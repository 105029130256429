import React from "react";
import img1 from "../../assests/baby-boy_1.png";
import img2 from "../../assests/wedding.jpg";
import img3 from "../../assests/prewedding.jpg";
import img4 from "../../assests/portrait.png";
import img5 from "../../assests/cinematograpy.jpg";
import img6 from "../../assests/mother.jpg";
import { Link } from "react-router-dom";

const Services = ({ imgdesc, heading, details, alternate_text }) => {
  // Assuming imgdesc is a number representing the image index
  const images = [img1, img2, img3, img4, img5, img6];
  const selectedImage = images[imgdesc - 1]; // Adjust the index if needed

  return (
    <div className="flex justify-center ">
      <div className="card h-[35rem] mt-6  w-96 shadow-xl bg-white rounded-2xl">
        <figure>
          <img
            className="w-96 rounded-lg overflow-hidden"
            src={selectedImage}
            alt={alternate_text}
            style={{ backgroundColor: "white" }}
          />
        </figure>
        <div className="card-body text-black ">
          {/* hello */}
          <div className="font-semibold flex justify-center">
            <h4>{heading}</h4>
          </div>
          <div className="flex flex-col text-lg h-full w-full justify-center">
            {details}
            <div className="flex h-full justify-end items-end">
              <Link
                to="/service"
                aria-current="page"
                className="flex gap-2 max-w-fit p-2 border solid rounded-2xl mt-2 bg-black text-white"
              >
                See More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="flex flex-col border border-solid border-white p-4 gap-1 my-3 rounded-lg h-96">
    //   <div className="flex rounded-lg overflow-hidden justify-center">
    //     <img
    //       className="w-96 rounded-lg overflow-hidden"
    //       src={selectedImage}
    //       alt={alternate_text}
    //       style={{ backgroundColor: "white" }}
    //     />
    //   </div>
    //   <div className="font-semibold flex justify-center">
    //     <h4>{heading}</h4>
    //   </div>
    //   <div className="flex flex-col text-lg h-full w-full justify-center">
    //     {details}
    //     <div className="flex h-full justify-end items-end">
    //       <Link
    //         to="/service"
    //         aria-current="page"
    //         className="flex gap-2 max-w-fit p-1 border solid rounded-lg mt-2"
    //       >
    //         see more
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           strokeWidth={1.5}
    //           stroke="currentColor"
    //           className="w-6 h-6"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
    //           />
    //         </svg>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Services;
