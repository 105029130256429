import Preset from "./components/Preset/Preset";
import Service from "./components/Services/Service";
import React from "react";
import Portfolio from "./components/Portfolio/Portfolio.jsx";
import Contact from "./components/Contact/Contact.jsx";
import { Route, Routes, } from "react-router-dom";
import Nav from "./components/Nav.jsx";
import Footer from "./components/Footer.jsx";
import Homepage from "./components/Homepage/Homepage.jsx";
import WhatsappFloatingIcon from "./components/WhatsappFloatingIcon.jsx"
import { UnikProvider } from "./context/UnikProvider";

function App() {
  return (
    <UnikProvider>
    <WhatsappFloatingIcon/>
    <div className="">
      <Nav />
      <Routes>
        
        <Route path="/" element={<Homepage />} />
        <Route path="/service" element={<Service />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/preset' element={<Preset/>}/>



   
      {/* <Portfolio /> */}
      </Routes>
      <Footer />
    </div>
    </UnikProvider>
  );
}

export default App;
